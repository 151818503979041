import { DasboardCardComponent } from "@components/admin/dashboards/DashboardCardComponent";
import { DashboardCardRowComponent } from "@components/admin/dashboards/DashboardCardRowComponent";
import { AdminUsersUniqueBuyersAndSellersCountsGetResponse } from "@reshopper/admin-client";
import { formatNumber } from "@utils/formatting";

export default function UniqueSellersComponent(props: {
	uniqueBuyersAndSellers: AdminUsersUniqueBuyersAndSellersCountsGetResponse, lastMonthString: string, twoMonthsAgoString: string
}) {

	return <DasboardCardComponent cardTitle={"MONTHLY UNIQUE SELLERS"} sizeNumberDividedWithHundred={3} >
		<DashboardCardRowComponent
			text={"This month"}
			value={formatNumber(props.uniqueBuyersAndSellers.currentMonthUniqueSellers ?? 0)}
			secondValue={formatNumber(props.uniqueBuyersAndSellers.currentMonthLastYearUniqueSellers ?? 0)} isLastRow={false} />
			<DashboardCardRowComponent
			text={props.lastMonthString}
			value={formatNumber(props.uniqueBuyersAndSellers.lastMonthUniqueSellers ?? 0)}
			secondValue={formatNumber(props.uniqueBuyersAndSellers.lastMonthLastYearUniqueSellers ?? 0)} isLastRow={false} />
			<DashboardCardRowComponent
			text={props.twoMonthsAgoString}
			value={formatNumber(props.uniqueBuyersAndSellers.twoMonthsAgoUniqueSellers ?? 0)}
			secondValue={formatNumber(props.uniqueBuyersAndSellers.twoMonthsAgoLastYearSellers ?? 0)} isLastRow={true} />
	</DasboardCardComponent> 
}
