import { DasboardCardComponent } from "@components/admin/dashboards/DashboardCardComponent";
import { DashboardCardRowComponent } from "@components/admin/dashboards/DashboardCardRowComponent";
import { AdminUsersUniqueBuyersAndSellersCountsGetResponse } from "@reshopper/admin-client";
import { formatNumber } from "@utils/formatting";

export default function UniqueBuyersComponent(props: {
	uniqueBuyersAndSellers: AdminUsersUniqueBuyersAndSellersCountsGetResponse, lastMonthString: string, twoMonthsAgoString: string
}) {

	return <DasboardCardComponent cardTitle={"MONTHLY UNIQUE BUYERS"} sizeNumberDividedWithHundred={3} >
		<DashboardCardRowComponent
				text={"This month"}
				value={formatNumber(props.uniqueBuyersAndSellers.currentMonthUniqueBuyers ?? 0)}
				secondValue={formatNumber(props.uniqueBuyersAndSellers.currentMonthLastYearUniqueBuyers ?? 0)} isLastRow={false} />
				<DashboardCardRowComponent
				text={props.lastMonthString}
				value={formatNumber(props.uniqueBuyersAndSellers.lastMonthUniqueBuyers ?? 0)}
				secondValue={formatNumber(props.uniqueBuyersAndSellers.lastMonthLastYearUniqueBuyers ?? 0)} isLastRow={false} />
				<DashboardCardRowComponent
				text={props.twoMonthsAgoString}
				value={formatNumber(props.uniqueBuyersAndSellers.twoMonthsAgoUniqueBuyers ?? 0)}
				secondValue={formatNumber(props.uniqueBuyersAndSellers.twoMonthsAgoLastYearUniqueBuyers ?? 0)} isLastRow={true} />
	</DasboardCardComponent> 
};

