import StatsGraphTableComponent from "@components/admin/stats/StatsGraphTableComponent";
import { AdminActiveSubscriptionsByPlusTypeCountsGetResponse, AdminPlusRevenueStatsResponse } from "@reshopper/admin-client";
import { formatNumber, toDanishPriceFormat } from "@utils/formatting";

export default function RevenueAndCountByPlusTypeSubscriptionsComponent(props:  {
   revenueByPlusTypeSubscriptions: AdminPlusRevenueStatsResponse, activeSubscriptionsByPlusTypeCount: AdminActiveSubscriptionsByPlusTypeCountsGetResponse}) {
   const plansWithRevenueResponse = props.revenueByPlusTypeSubscriptions.plansWithRevenues;
   let free = toStatsTableWithCountAndSum("Free", props.activeSubscriptionsByPlusTypeCount.activeSubscriptionsFree,
   plansWithRevenueResponse["reepay_subscription_free_1"] as number)
   let basic = toStatsTableWithCountAndSum("Basic", props.activeSubscriptionsByPlusTypeCount.activeSubscriptionsBasic,
   plansWithRevenueResponse["reepay_subscription_basic_1"] as number)
   let premium1 = toStatsTableWithCountAndSum("Premium1", props.activeSubscriptionsByPlusTypeCount.activeSubscriptionsPremium1Month,
   plansWithRevenueResponse["reepay_subscription_1"] as number)
   let premium12 = toStatsTableWithCountAndSum("Premium12", props.activeSubscriptionsByPlusTypeCount.activeSubscriptionsPremium12Month,
   plansWithRevenueResponse["reepay_subscription_12"] as number)
   let business = toStatsTableWithCountAndSum("Business", props.activeSubscriptionsByPlusTypeCount.activeSubscriptionsBusinessNgo + props.activeSubscriptionsByPlusTypeCount.activeSubscriptionsBusinessSecondHand,
   plansWithRevenueResponse["Business"] as number)
  
   const plansWithRevenue = new Map<string,string>([
      [free.textToShow, free.revenueToShow],
      [basic.textToShow, basic.revenueToShow],
      [premium1.textToShow, premium1.revenueToShow],
      [premium12.textToShow, premium12.revenueToShow],
      [business.textToShow, business.revenueToShow],
		["All revenue", toDanishPriceFormat(props.revenueByPlusTypeSubscriptions.allRevenueCombined ? Math.round(props.revenueByPlusTypeSubscriptions.allRevenueCombined / 100) : 0)]
      ],
   )

   function toStatsTableWithCountAndSum (textToShow: string, count: number, sum: number ) {
      textToShow += " (" + formatNumber(count) + ")"
      let revenueToShow = toDanishPriceFormat(sum ? Math.round(sum as number / 100) : 0)
      return {textToShow: textToShow, revenueToShow: revenueToShow}
   }

   return <StatsGraphTableComponent 
      tableStats={plansWithRevenue}
      tableTitle={"PLUS"}
      variant="large"
   />
}