import StatsGraphTableComponent from "@components/admin/stats/StatsGraphTableComponent";
import { CreditsRevenueStat } from "@reshopper/admin-client";
import { toDanishPriceFormat } from "@utils/formatting";
import { addDays, addMonths, addYears } from "date-fns";

export function CreditsRevenueStatsComponent(props: {
	creditsStats: CreditsRevenueStat[],
}) {

	function convertCreditsRevenueStatsToMap() {
		const now = new Date();
		const todaysCreditRevenue = props.creditsStats &&
			props.creditsStats.filter(x =>
				x.timeUtc.getFullYear() === now.getFullYear() &&
				x.timeUtc.getMonth() === now.getMonth() &&
				x.timeUtc.getDate() === now.getDate())
				.reduce((sum, current) => sum + current.totalPriceInHundreds, 0);

		const yesterdaysCreditRevenue = props.creditsStats &&
			props.creditsStats.filter(x =>
				x.timeUtc.getFullYear() === addDays(now, -1).getFullYear() &&
				x.timeUtc.getMonth() === addDays(now, -1).getMonth() &&
				x.timeUtc.getDate() === addDays(now, -1).getDate())
				.reduce((sum, current) => sum + current.totalPriceInHundreds, 0);

		const lastWeeksCreditRevenue = props.creditsStats &&
			props.creditsStats.filter(x => x.timeUtc > addDays(now, -7))
				.reduce((sum, current) => sum + current.totalPriceInHundreds, 0);

		const lastMonthsCreditRevenue = props.creditsStats &&
			props.creditsStats.filter(x => x.timeUtc > addDays(now, -30))
				.reduce((sum, current) => sum + current.totalPriceInHundreds, 0);

		const lastQuarterCreditRevenue = props.creditsStats &&
			props.creditsStats.filter(x => x.timeUtc > addMonths(now, -3))
				.reduce((sum, current) => sum + current.totalPriceInHundreds, 0);

		const lastYearCreditRevenue = props.creditsStats &&
			props.creditsStats.filter(x => x.timeUtc > addYears(now, -1))
				.reduce((sum, current) => sum + current.totalPriceInHundreds, 0);


		return new Map<string, string>([
			["Today",
				toDanishPriceFormat(todaysCreditRevenue ?
					Math.round(todaysCreditRevenue / 100) :
					0)],
			["Yesterday",
				toDanishPriceFormat(yesterdaysCreditRevenue ?
					Math.round(yesterdaysCreditRevenue / 100) :
					0)],
			["Last 7 days", toDanishPriceFormat(lastWeeksCreditRevenue ? Math.round(lastWeeksCreditRevenue / 100) : 0)],
			["Last 30 days", toDanishPriceFormat(lastMonthsCreditRevenue ? Math.round(lastMonthsCreditRevenue / 100) : 0)],
			["Last 90 days", toDanishPriceFormat(lastQuarterCreditRevenue ? Math.round(lastQuarterCreditRevenue / 100) : 0)],
			["Last 365 days", toDanishPriceFormat(lastYearCreditRevenue ? Math.round(lastYearCreditRevenue / 100) : 0)]
		])
	}

	return <StatsGraphTableComponent
		tableStats={convertCreditsRevenueStatsToMap()}
		tableTitle={"CREDITS REVENUE"}
		variant="large"
	/>
}