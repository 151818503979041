import StatsGraphTableComponent from "@components/admin/stats/StatsGraphTableComponent";
import { AdminTotalRatingsCountGetResponse, AdminUsersWithEmailMarketingActiveCountGetResponse, AdminUsersWithInAppMarketingActiveCountGetResponse, AdminUsersWithPushNotificationMarketingActiveCountGetResponse, AdminUsersWithVerifiedEmailCountGetResponse, AdminUsersWithVerifiedPhoneCountGetResponse } from "@reshopper/admin-client";
import { formatNumber } from "@utils/formatting";

export default function MiscellaneousComponent(props:  {
	totalRatingsCount: AdminTotalRatingsCountGetResponse,
	usersWithVerifiedEmailCount: AdminUsersWithVerifiedEmailCountGetResponse,
	usersWithEMailMarketingActiveCount: AdminUsersWithEmailMarketingActiveCountGetResponse,
	usersWithVerifiedPhoneCount: AdminUsersWithVerifiedPhoneCountGetResponse,
	UsersWithInAppMarketingActiveCount: AdminUsersWithInAppMarketingActiveCountGetResponse,
	UsersWithPushNotificationMarketingActiveCount: AdminUsersWithPushNotificationMarketingActiveCountGetResponse,
}) {
	function convertMiscellaneousDataToMap() {
		return new Map<string, string>([
			["Total ratings", formatNumber(props.totalRatingsCount?.count).toString()],
			["E-mails verified", formatNumber(props.usersWithVerifiedEmailCount?.count).toString()],
			["Phones validated", formatNumber(props.usersWithVerifiedPhoneCount?.count).toString()],
			["E-mail marketing", formatNumber(props.usersWithEMailMarketingActiveCount?.count).toString()],
			["In-App marketing", formatNumber(props.UsersWithInAppMarketingActiveCount?.count).toString()],
			["Push marketing", formatNumber(props.UsersWithPushNotificationMarketingActiveCount?.count).toString()],
		])
	}

	return <StatsGraphTableComponent
		tableStats={convertMiscellaneousDataToMap()}
		tableTitle={"MISCELLANEOUS"}
		variant="large"
	/>
}