import { adminClient } from "@api/admin/AdminClient";
import { DashboardComponent } from "@components/admin/dashboards/DashboardComponent";
import { CreditsRevenueStatsComponent } from "@components/admin/dashboards/key-performance-indicators/CreditsStatsComponent";
import KeyPerformanceForUsersComponent from "@components/admin/dashboards/key-performance-indicators/KeyPerformanceForUsersComponent";
import MiscellaneousComponent from "@components/admin/dashboards/key-performance-indicators/miscellaneous/MiscellaneousComponent";
import RevenueAndCountByPlusTypeSubscriptionsComponent from "@components/admin/dashboards/RevenueByPlusTypeSubsciptionsComponent";
import UniqueBuyersComponent from "@components/admin/dashboards/UniqueBuyersComponent";
import UniqueSellersComponent from "@components/admin/dashboards/UniqueSellersComponent";
import { Box } from "@material-ui/core";
import { AdminActiveSubscriptionsByPlusTypeCountsGetResponse, AdminKpiUsersResponse, AdminPlusRevenueStatsResponse, AdminTotalRatingsCountGetResponse, AdminUsersUniqueBuyersAndSellersCountsGetResponse, AdminUsersWithEmailMarketingActiveCountGetResponse, AdminUsersWithInAppMarketingActiveCountGetResponse, AdminUsersWithPushNotificationMarketingActiveCountGetResponse, AdminUsersWithVerifiedEmailCountGetResponse, AdminUsersWithVerifiedPhoneCountGetResponse, CreditsRevenueStat } from "@reshopper/admin-client";
import { Countries } from "@reshopper/web-client";
import { toDanishDateFormat } from "@utils/miscellaneous";
import { toPascalCase } from "@utils/strings";
import { addYears } from "date-fns";
import { useState } from "react";


export default function KpiDashboardComponent() {
	const [country, setCountry] = useState<Countries>("dk");
	const [lastRefreshUtc, setLastRefreshUtc] = useState<Date>(new Date());
	const [keyPerformanceForUsers, setKeyPerformanceForUsers] = useState<AdminKpiUsersResponse>();
	const [creditsStats, setCreditsData] = useState<CreditsRevenueStat[]>();
	const [totalRatingsCount, setTotalRatingsCount] = useState<AdminTotalRatingsCountGetResponse>();
	const [usersWithEMailMarketingActiveCount, setUsersWithEMailMarketingActiveCount] = useState<AdminUsersWithEmailMarketingActiveCountGetResponse>();
	const [usersWithVerifiedEmailCount, setUsersWithVerifiedEmailCount] = useState<AdminUsersWithVerifiedEmailCountGetResponse>();
	const [usersWithVerifiedPhoneCount, setUsersWithVerifiedPhoneCount] = useState<AdminUsersWithVerifiedPhoneCountGetResponse>();
	const [usersWithInAppMarketingActiveCount, setUsersWithInAppMarketingActiveCount] = useState<AdminUsersWithInAppMarketingActiveCountGetResponse>();
	const [usersWithPushNotificationMarketingActiveCount, setUsersWithPushNotificationMarketingActiveCount] = useState<AdminUsersWithPushNotificationMarketingActiveCountGetResponse>();
	const [uniqueBuyersAndSellers, setUniqueBuyersAndSellers] = useState<AdminUsersUniqueBuyersAndSellersCountsGetResponse>();
	const [revenueByPlusTypeSubscriptions, setrevenueByPlusTypeSubscriptions] = useState<AdminPlusRevenueStatsResponse>();
	const [activeSubscriptionsByPlusTypeCount, setActiveSubscriptionsByPlusTypeCount] = useState<AdminActiveSubscriptionsByPlusTypeCountsGetResponse>();

	async function fetchData() {
		fetchKeyPeformanceIndicators(country);
		fetchCreditsData();
		fetchTotalRatingsCount();
		fetchUsersWithEMailMarketingActiveCount();
		fetchUsersWithInAppMarketingActiveCount();
		fetchUsersWithPushNotificationMarketingActiveCount();
		fetchUsersWithVerifiedEmailCount();
		fetchUsersWithVerifiedPhoneCount();
		fetchUniqueBuyersAndSellers();
		fetchRevenueByPlusTypeSubscriptions();
		fetchActiveSubscriptionsByPlusType();
	}

	async function fetchKeyPeformanceIndicators(country: Countries) {
		let kpiStats = await adminClient().adminKpiStatsPost({
			body: {
				country
			}
		})
		setKeyPerformanceForUsers(kpiStats.kpiUsers);
	};

	async function fetchCreditsData() {
		const now = new Date();
		let creditsRevenueStatsList = await adminClient().adminCreditGetCreditsRevenueStatsPost({
			body: {
				from: addYears(now, -1),
				to: now
			}
		});
		var creditRevenueStats: CreditsRevenueStat[] = [];
		creditsRevenueStatsList.creditRevenueStatsWithChangeList.forEach(r => r.creditRevenueStatsList.forEach(r => creditRevenueStats.push(r)));
		setCreditsData(creditRevenueStats);
	};

	async function fetchTotalRatingsCount() {
		var ratingsCount = await adminClient().adminTotalRatingsCountGet();
		setTotalRatingsCount(ratingsCount);
	};

	async function fetchUsersWithEMailMarketingActiveCount() {
		var usersWithEMailMarketingActiveCount = await adminClient().adminUsersWithEmailMarketingActiveCountGet();
		setUsersWithEMailMarketingActiveCount(usersWithEMailMarketingActiveCount);
	};

	async function fetchUsersWithVerifiedEmailCount() {
		var usersWithVerifiedEmailTotalCount = await adminClient().adminUsersWithVerifiedEmailCountGet();
		setUsersWithVerifiedEmailCount(usersWithVerifiedEmailTotalCount);
	};

	async function fetchUsersWithVerifiedPhoneCount() {
		var usersWithVerifiedPhoneTotalCount = await adminClient().adminUsersWithVerifiedPhoneCountGet();
		setUsersWithVerifiedPhoneCount(usersWithVerifiedPhoneTotalCount);
	};

	async function fetchUsersWithInAppMarketingActiveCount() {
		var usersWithInAppMarketingActiveCount = await adminClient().adminUsersWithInAppMarketingActiveCountGet();
		setUsersWithInAppMarketingActiveCount(usersWithInAppMarketingActiveCount);
	};

	async function fetchUsersWithPushNotificationMarketingActiveCount() {
		var usersWithPushNotificationMarketingActiveCount = await adminClient().adminUsersWithPushNotificationMarketingActiveCountGet();
		setUsersWithPushNotificationMarketingActiveCount(usersWithPushNotificationMarketingActiveCount);
	};

	async function fetchUniqueBuyersAndSellers() {
		var UniqueBuyersAndSellers = await adminClient().adminUsersUniqueBuyersAndSellersCountsGet(country);
		setUniqueBuyersAndSellers(UniqueBuyersAndSellers);
	};

	async function fetchRevenueByPlusTypeSubscriptions() {
		var revenueByPlusTypeSubscriptions = await adminClient().adminPlusRevenueStats();
		setrevenueByPlusTypeSubscriptions(revenueByPlusTypeSubscriptions);
	};

	async function fetchActiveSubscriptionsByPlusType() {
		var activeSubscriptionsByPlusType = await adminClient().adminActiveSubscriptionsByPlusTypeCountsGet();
		setActiveSubscriptionsByPlusTypeCount(activeSubscriptionsByPlusType);
	};

	function setLastMonthDateToString() {
		let lastMonth = new Date();
		lastMonth.setMonth(lastMonth.getMonth() - 1);

		let thisMonth = new Date();
		if(thisMonth.getMonth() == lastMonth.getMonth()){
			lastMonth.setMonth(lastMonth.getMonth() - 1);
		}

		return toPascalCase(toDanishDateFormat(lastMonth, "MMM")) + " " + toDanishDateFormat(lastMonth, "y");
	}

	function setTwoMonthsAgoDateToString() {
		let twoMonthsAgo = new Date();
		twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);

		return toPascalCase(toDanishDateFormat(twoMonthsAgo, "MMM")) + " " + toDanishDateFormat(twoMonthsAgo, "y");
	}

	return <DashboardComponent
		onRefresh={async country => {
			setCountry(country);
			setLastRefreshUtc(new Date());
			fetchData();
		}}>

		<Box
			display="flex"
			flexDirection="row"
			flexWrap="Wrap"
		>
			{country && keyPerformanceForUsers && <KeyPerformanceForUsersComponent country={country} keyPerformanceForUsers={keyPerformanceForUsers} />}
			{creditsStats && <CreditsRevenueStatsComponent creditsStats={creditsStats} />}

			{totalRatingsCount &&
				usersWithVerifiedEmailCount &&
				usersWithVerifiedPhoneCount &&
				usersWithEMailMarketingActiveCount &&
				usersWithInAppMarketingActiveCount &&
				usersWithPushNotificationMarketingActiveCount &&
				<MiscellaneousComponent
					totalRatingsCount={totalRatingsCount}
					usersWithVerifiedEmailCount={usersWithVerifiedEmailCount}
					usersWithVerifiedPhoneCount={usersWithVerifiedPhoneCount}
					usersWithEMailMarketingActiveCount={usersWithEMailMarketingActiveCount}
					UsersWithInAppMarketingActiveCount={usersWithInAppMarketingActiveCount}
					UsersWithPushNotificationMarketingActiveCount={usersWithPushNotificationMarketingActiveCount}

				/>}
		</Box>

		<Box
			display="flex"
			flexDirection="row"
			flexWrap="Wrap"
			height="48.8vh"

		>
			{uniqueBuyersAndSellers &&
				<UniqueSellersComponent uniqueBuyersAndSellers={uniqueBuyersAndSellers}
					lastMonthString={setLastMonthDateToString()}
					twoMonthsAgoString={setTwoMonthsAgoDateToString()} />}

			{uniqueBuyersAndSellers &&
				<UniqueBuyersComponent uniqueBuyersAndSellers={uniqueBuyersAndSellers}
					lastMonthString={setLastMonthDateToString()}
					twoMonthsAgoString={setTwoMonthsAgoDateToString()} />}

			{revenueByPlusTypeSubscriptions &&
				activeSubscriptionsByPlusTypeCount &&
				<RevenueAndCountByPlusTypeSubscriptionsComponent
					revenueByPlusTypeSubscriptions={revenueByPlusTypeSubscriptions}
					activeSubscriptionsByPlusTypeCount={activeSubscriptionsByPlusTypeCount} />}
		</Box>

		<div >
			<footer  >

				{toDanishDateFormat(lastRefreshUtc)}
			</footer>
		</div>
	</DashboardComponent>
}
