import StatsGraphTableComponent from "@components/admin/stats/StatsGraphTableComponent";
import { AdminKpiUsersResponse } from "@reshopper/admin-client";
import { Countries } from "@reshopper/web-client";
import { formatNumber } from "@utils/formatting";

export default function KeyPerformanceForUsersComponent(props: {
	country: Countries,
	keyPerformanceForUsers: AdminKpiUsersResponse
}) {

	function convertKeyPerformanceIndicatorsForUsersToMap() {
		return new Map<string, string>([
			["Total users", formatNumber(props.keyPerformanceForUsers?.totalUsers).toString()],
			["Inactive 45-90 days ", formatNumber(props.keyPerformanceForUsers?.inactiveUsers45To90Days).toString()],
			["Inactive 90-180 days", formatNumber(props.keyPerformanceForUsers?.inactiveUsers90To180Days).toString()],
			["Inactive 180-365 days", formatNumber(props.keyPerformanceForUsers?.inactiveUsers180To365Days).toString()],
			["New last 30 days", formatNumber(props.keyPerformanceForUsers?.newUsersAddedPastMonth).toString()],
			["Deleted last 30 days", formatNumber(props.keyPerformanceForUsers?.deletedUsersPastMonth).toString()]
		])
	}

	return <StatsGraphTableComponent
		tableStats={convertKeyPerformanceIndicatorsForUsersToMap()}
		tableTitle={"USERS"}
		variant="large"
	/>
}

